<template>
  <div class="container">
    <div class="box">
      <div
        class="fullGlass"
        :class="{ emptyGlass: !glass }"
        v-for="(glass, index) in glasses"
        :key="index"
        :id="index"
        :name="glass"
        @click="toggleGlass(index)"
      ></div>
    </div>
  </div>

  <div class="bottomMenu">
    <a class="addGlass" @click="addGlass"><span>+</span></a>
    <a class="reset" @click="resetAll"><span>reset</span></a>
    <a class="share" @click="startShare"><span>click & share</span></a>
    <!-- <a class="share" @click="checkIsCookie"><span>checkIsCookie</span></a> -->
    <a class="withLove"><span>made with 💙 to simplicity</span></a>
  </div>

  <!-- <div class="addGlass"><a @click="addGlass">+</a></div> -->
</template>

<script>
import { ref } from "@vue/reactivity";
import Cookies from "js-cookie";
import { onBeforeMount, watchEffect } from "@vue/runtime-core";
import { useShare } from "@vueuse/core";

export default {
  setup() {
    const { share, isSupported } = useShare();

    let glasses = ref([
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ]);

    const toggleGlass = (index) => {
      let cyrrentItem = glasses.value[index];

      if (cyrrentItem) {
        glasses.value[index] = false;
      } else {
        glasses.value[index] = true;
      }

      console.log(glasses.value);
    };

    //////
    const checkIsCookie = () => {
      if (localStorage.getItem("waterChecklist")) {
        console.log("waterChecklist istnieje");

        glasses.value = localStorage
          .getItem("waterChecklist")
          .split(",")
          .map((item) => {
            if (item === "true") return true;
            if (item === "false") return false;
          });
      } else {
        localStorage.setItem("waterChecklist", glasses.value);
        console.log("🍪 storage zrobiony");
      }
    };
    // const checkIsCookie = () => {
    //   if (Cookies.get("waterChecklist")) {
    //     console.log("🍪 Cookie istnieje:", Cookies.get("waterChecklist"));

    //     glasses.value = Cookies.get("waterChecklist")
    //       .split(",")
    //       .map((item) => {
    //         if (item === "true") return true;
    //         if (item === "false") return false;
    //       });
    //   } else {
    //     console.log("🍪 nie ma cookiesa ale zaraz bedzie");
    //     // Cookies.set("waterChecklist", glasses.value);
    //   }
    // };

    /////
    checkIsCookie();

    const addGlass = () => {
      glasses.value.push(true);
    };
    const resetAll = () => {
      glasses.value = Array(12).fill(true, 0, 12);
    };

    const startShare = () => {
      share({
        title: "💧 Water Checklist",
        text: "💧 Water Checklist that I'was talking about...",
        url: location.href,
      });
    };
    watchEffect(() => {
      console.log("🔥", glasses.value);
      localStorage.setItem("waterChecklist", glasses.value);
    });

    return {
      glasses,
      toggleGlass,
      resetAll,
      addGlass,
      checkIsCookie,
      startShare,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0039d0;
  position: absolute;
  top: 0;
  width: 100%;
}
body {
  background-color: white;
  margin-left: auto;
  margin-right: auto;
}
.container {
  background-color: #0047ff;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin-top: -50px;
  padding-left: 15px;
  padding-right: 15px;
}

.fullGlass {
  display: flex;
  flex-basis: 33%;
  width: 60px;
  aspect-ratio: 1;
  background-image: url("./assets/full.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right -30px bottom 0px;
  padding-top: 35px;
}
.fullGlass:hover {
  cursor: pointer;
}

.emptyGlass {
  background-image: url("./assets/empty.png");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.3;
}

.bottomMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bottomMenu a {
  height: 115px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
}

.bottomMenu span {
  margin-left: auto;
  margin-right: auto;
}

.withLove {
  background-color: white;
  color: #5686fe;
  text-decoration: none;
}

.reset {
  /* background-color: #5889fe; */
  background-color: #0039d0;
  color: #5889fe;
  text-decoration: none;
}
.share {
  background-color: #638fff;
  color: #0047ff;

  text-decoration: none;
}
.addGlass {
  background-color: #0047ff;
  color: #5686fe;
  text-decoration: none;
}

.addGlass span {
  display: flex;
  width: 39px;
  height: 39px;
  line-height: 39px;
  border: 2px solid #5889fe;
  color: #5889fe;
  background-color: #0047ff;
  border-radius: 100px;
  justify-content: center;
}
</style>
